@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

body {
  font-family: 'Comfortaa', cursive;
  color: white;
  background-color: #EF199F;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: #EF199F;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin-right: 20px;
}
nav {
  margin-bottom: 120px;
}

h1 {
  font-family: "Bebas Neue", "Ostrich Sans", "Oswald", sans-serif;
  font-size: 6rem;
  letter-spacing: 0.2em;
  line-height: 1.1;
}
h1 span {
  display: inline-block;
}

h2 {
  font-family: "Bebas Neue", "Ostrich Sans", "Oswald", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.1em;
  margin: 0;
}

div#header {
  border-top: 3px solid #EF199F;
  padding: 80px;
  position: relative;
  background-color: rgba(0,0,0,0.6);
}

.section {
  padding: 80px;
  max-width: 1024px;
  margin: 0 auto;
}

.maxwidth {
  max-width: 1024px;
  margin: 0 auto;
}

div#background {
  background: url('/public/images/bg.jpg') no-repeat center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  box-shadow: 0 0 200px rgba(0,0,0,0.9) inset;
}

p, li {
  font-size: 1.1rem;
  line-height: 1.6;
}

a.button {
  border: 1px solid white;
  padding: 12px 20px;
  border-radius: 28px;
}
a.button:hover {
  background-color: #c90481;
  color: white;
}

div#contact {
  border-top: 2px dotted white;
}

div#socials {
  text-align: right;
  font-size: 1.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 80px;
}
div#socials > div {
  flex-basis: 100%;
  flex: 1;
}
div#socials i {
  margin-left: 20px;
}
div#socials a:hover {
  color: white;
}

div.story {
  border: 2px dotted white;
  padding: 0px 20px;
  margin: 40px 0px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

@media screen and (max-width: 720px) {
  div#header, div.section {
    padding: 20px;
  }
}
